import React from 'react';
import CardWrapper from 'Components/Common/Card/Card';
import { formatDate } from 'Utils/DateTime';
import ViewOnlyField from 'Components/Page/Campaign/RegisterCampaign/Common/ViewOnlyField';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { FIELD_SIZE, limitUserSetting } from 'Constant/Campaign';
import PrizesTable from 'Components/Page/Campaign/RegisterCampaign/Step4/PrizesTable';
// import TableWinningConditionResult from 'Components/Page/Campaign/RegisterCampaign/Step3/TableWinningConditionResult'; // comment for hide blacklist
import MessageError from 'Components/Common/MessageError';
import { find } from 'lodash';
import BlackListsTable from './BlackListsTable';

const StepConfirmView = ({
  overview,
  prizes,
  blacklists,
  // whitelists,
  errors,
}: {
  overview: any;
  prizes: any;
  blacklists: any;
  // whitelists: any;
  errors?: any;
}): JSX.Element => {
  const formSize = {
    labelSize: FIELD_SIZE.label,
    valueSize: FIELD_SIZE.value,
  };

  return (
    <>
      <CardWrapper title="Tổng quan về chiến dịch" className="mb-3">
        <ViewOnlyField
          {...formSize}
          label="Tên chiến dịch:"
          value={overview?.name}
          isMessageInside
          errorMessage={errors?.name?.message}
        />
        <ViewOnlyField
          {...formSize}
          label="Thời gian diễn ra:"
          value={
            overview?.startDate &&
            `${formatDate(overview?.startDate, DATE_FORMAT_2)} ~ ${formatDate(
              overview?.endDate,
              DATE_FORMAT_2,
            )}`
          }
          isMessageInside
          errorMessage={errors?.startDate?.message || errors?.endDate?.message}
        />
        <ViewOnlyField
          {...formSize}
          label="Thể lệ chương trình:"
          value={overview?.description}
          isMessageInside
          errorMessage={errors?.description?.message}
          prevLine
        />
        <ViewOnlyField
          {...formSize}
          label="Đối tượng tham gia:"
          value={
            find(limitUserSetting, (option) => option.value === overview?.limitUser)?.label ?? ''
          }
          isMessageInside
          errorMessage={errors?.description?.message}
          prevLine
        />
      </CardWrapper>
      <CardWrapper title="Danh sách giải thưởng" className="mb-3 view-only">
        <div className="mb-3">
          <PrizesTable prizes={prizes} />
          <MessageError classWrapper="me-0" message={errors?.prizes?.message} />
        </div>
      </CardWrapper>
      <CardWrapper title="Danh sách chặn" className="mb-3 view-only">
        <div className="mb-3">
          <BlackListsTable blacklists={blacklists} />

          <MessageError classWrapper="me-0" message={errors?.prizes?.message} />
        </div>
      </CardWrapper>
      {/* <CardWrapper title="Danh sách chặn" className="mb-3 view-only"> // comment for hide blacklist
        <div>
          <TableWinningConditionResult
            dataSource={blacklists ?? []}
            viewOnly={true}
            optionsType={blacklistOptionsType}
          />
          <MessageError classWrapper="me-0" message={errors?.blacklists?.message} />
        </div>
      </CardWrapper>
      <CardWrapper title="Danh sách ưu tiên" className="mb-3 view-only">
        <div>
          <TableWinningConditionResult
            dataSource={whitelists ?? []}
            viewOnly={true}
            optionsType={whitelistOptionsType}
          />
          <MessageError classWrapper="me-0" message={errors?.whitelists?.message} />
        </div>
      </CardWrapper> */}
    </>
  );
};

export default StepConfirmView;
