import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface PopupProps {
  title?: string;
  show: boolean;
  handleVisible: () => void;
}

export default function FQAPopup({ show, title, handleVisible }: PopupProps): JSX.Element {
  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={() => handleVisible()}
        dialogClassName="modal-90w modal-landing"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-truncate d-flex justify-content-center m-auto"
          >
            <p className="title-header mb-0 text-truncate text-header">{title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-center">
              <div className="d-flex flex-column px-3 faq">
                <p>1. Tại sao tôi không nhận được mã dự thưởng?</p>
                <p className="ps-3">
                  - Mã dự thưởng sẽ được hiển thị ngay sau khi kết thúc trả lời câu hỏi khảo sát
                  trước khi tiến hành quay số. Quý khách vui lòng lưu lại mã dự thưởng sau khi hoàn
                  thành trả lời câu hỏi khảo sát trước khi quay số bằng cách sao chép hoặc chụp ảnh
                  màn hình lại
                </p>
                <p className="pt-3">2. Tôi phải làm gì để lấy lại mã dự thưởng?</p>
                <p className="ps-3">
                  - Quý khách vui lòng hoàn thành câu hỏi khảo sát. Trong trường hợp không lưu lại
                  mã dự thưởng, Quý khách vui gọi điện lên tổng đài MobiFone 18001090 để được hỗ
                  trợ.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-center">
              <Button variant="danger fs-18 rounded-pill text-bold" onClick={() => handleVisible()}>
                Đóng lại
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
