/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import JSCookie from 'js-cookie';

const brAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 100000,
});

const requestHandler = (request: any) => {
  const authToken = JSCookie.get('token');
  request.headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'X-Requested-With',
    Authorization: `Bearer ${authToken}`,
  };
  return request;
};

const redirectToLogin = (response: any) => {
  if (response?.status === 401 || response?.status === 403) {
    JSCookie.remove('token');
    window.location = <any>'/admin/login';
  }
};

const responseHandler = (response: any) => {
  return response;
};

const errorHandler = (error: any) => {
  const { response } = error;
  redirectToLogin(response);
  return Promise.reject(error);
};

brAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error),
);

brAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error),
);

const postFormData = (url: string, params: any) => {
  return brAxios.post(url, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// const putFormData = (url: string, params: any) => {
//   return brAxios.put(`/${url}`, params, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   });
// };
const putFormData = (url: string, params: any) => {
  return brAxios.put(url, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export default {
  get: brAxios.get,
  post: brAxios.post,
  put: brAxios.put,
  delete: brAxios.delete,
  patch: brAxios.patch,
  postFormData: postFormData,
  putFormData: putFormData,
};
