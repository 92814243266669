import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { BlackList } from 'Types/Page/Campaign/RegisterCampaign';
import TableRowBlackList from './TableRowBlackList';

interface TableProps {
  handleShow: (item: any) => void;
  blacklists: BlackList[];
  isDisabledItem?: boolean;
}

const TableBlackList = ({ handleShow, blacklists }: TableProps): JSX.Element => {
  const [items, setItems] = useState(blacklists);
  useEffect(() => {
    setItems(blacklists);
  }, [blacklists]);

  return (
    <div className="table-award">
      <Table className="table-campaign table-award-campaign header-light-gray" responsive>
        <thead>
          <tr>
            <th className="w-10">Ip</th>
            <th className="w-30">Số điện thoại</th>
            <th className="w-10">Địa chỉ</th>
            <th className="w-10">Tuổi</th>
            <th className="">Cấu hình thêm</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {isEmpty(items) ? (
            <tr>
              <td className="text-center align-middle" colSpan={12}>
                Chưa đăng ký
              </td>
            </tr>
          ) : (
            items.map((value, index) => {
              return (
                <TableRowBlackList
                  key={`item-${index}`}
                  ip={value?.ip}
                  phone={value?.phone}
                  location={value?.location}
                  typeAge={value?.typeAge}
                  vlr={value?.vlr}
                  vlrOperator={value?.vlrOperator}
                  kndl={value?.kndl}
                  kndlOperator={value?.kndlOperator}
                  khln={value?.khln}
                  khlnOperator={value?.khlnOperator}
                  addFields={value?.addFields}
                  handleEditItem={() => handleShow({ ...value, index })}
                />
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(TableBlackList);
