import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import TableRow from 'Components/Page/Campaign/RegisterCampaign/Step2/TableRow';
import { prizeOptionsType } from 'Constant/Campaign';
import { find, isEmpty } from 'lodash';
import { Prize } from 'Types/Page/Campaign/RegisterCampaign';

interface TableProps {
  handleShow: (item: any) => void;
  prizes: Prize[];
  isDisabledItem?: boolean;
}

const TableAward = ({ handleShow, prizes }: TableProps): JSX.Element => {
  const [items, setItems] = useState(prizes);
  useEffect(() => {
    setItems(prizes);
  }, [prizes]);

  return (
    <div className="table-award">
      <Table className="table-campaign table-award-campaign header-light-gray" responsive>
        <thead>
          <tr>
            <th className="w-30">Tên giải thưởng</th>
            <th className="w-10">Loại</th>
            <th className="w-10">Vị trí</th>
            <th className="w-40">Cài đặt thời gian trúng giải</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {isEmpty(items) ? (
            <tr>
              <td className="text-center align-middle" colSpan={4}>
                Chưa đăng ký
              </td>
            </tr>
          ) : (
            items.map((value, index) => {
              const awardType = find(prizeOptionsType, (option) => option.value === value.type);
              return (
                <TableRow
                  key={`item-${index}`}
                  name={value?.name}
                  awardType={awardType?.label}
                  positionWheel={value?.positionWheel}
                  settingProbabilities={value.settingProbabilities}
                  handleEditItem={() => handleShow({ ...value, index })}
                />
              );
            })
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(TableAward);
