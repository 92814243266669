import React from 'react';

interface RowTypes {
  ip?: string;
  phone?: string;
  location?: string;
  typeAge?: string;
  vlr?: string;
  vlrOperator?: string;
  kndl?: string;
  kndlOperator?: string;
  khln?: string;
  khlnOperator?: string;
  addFields?: string;
  className?: string;
}

const TableBlackListRow = ({
  ip,
  phone,
  location,
  typeAge,
  // vlr,
  // vlrOperator,
  // kndl,
  // kndlOperator,
  // khln,
  // khlnOperator,
  addFields,
  className,
}: RowTypes): JSX.Element => {
  // const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  // const settingProbabilitiesShow = isLoadMore
  //   ? settingProbabilities
  //   : settingProbabilities.slice(0, 2);
  let parsedAddFields: any[] = [];
  if (addFields) {
    try {
      parsedAddFields = addFields ? JSON.parse(addFields) : [];
    } catch (error) {
      console.error('Failed to parse addFields', error);
    }
  }
  return (
    <tr className={`tr-drag ${className}`}>
      <td>{ip}</td>
      <td>{phone}</td>
      <td>{location}</td>
      <td>{typeAge}</td>
      <td>
        {parsedAddFields.map((value, index) => {
          return (
            <div key={`item-${index}`}>
              <p>
                {value.label}({value.field}): {value.operator === '=' ? '' : value.operator}{' '}
                {value.data}
              </p>
            </div>
          );
        })}
      </td>
      {/* <td>
        {isEmpty(settingProbabilities)
          ? 'Chưa cài đặt thời gian trúng giải'
          : settingProbabilitiesShow.map((value, index) => {
              const pickType = find(prizePickType, (option) => option.value === value.pickType);
              return (
                <div key={`item-${index}`}>
                  <p>Đối tượng: {pickType?.label}</p>
                  <p>Số lượng: {value.quantity}</p>
                  <p>Xác suất: {value.probability}%</p>
                  <p>
                    Thời gian bắt đầu:{' '}
                    {handleParseDate({
                      date: value.startDate,
                      format: DATE_FORMAT_2,
                    })}
                  </p>
                  <p>
                    Thời gian kết thúc:{' '}
                    {handleParseDate({
                      date: value.endDate,
                      format: DATE_FORMAT_2,
                    })}
                  </p>
                  {index == settingProbabilitiesShow.length - 1 ? '' : <hr className="my-3" />}
                </div>
              );
            })}
        {settingProbabilities.length > 2 && (
          <Button onClick={() => setIsLoadMore(!isLoadMore)} className="btn-focus-none fs-14 mt-4">
            {isLoadMore ? 'Ẩn bớt' : 'Xem thêm '}
          </Button>
        )}
      </td> */}
    </tr>
  );
};

export default TableBlackListRow;
