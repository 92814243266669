import React, { useMemo } from 'react';
import TableCommon from 'Components/Common/Table/TableCommon';
import { handleParseDate } from 'Utils/ParseDate';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { Cell } from 'react-table';
import { UserCampaignStatus } from 'Constant/Campaign';
// import { Button } from 'react-bootstrap';

interface IListCD {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onShowPhonePopup?: (data: any) => void;
  dataSource: any;
  [key: string]: any;
}

export default function UserCampaignTable({ dataSource, onShowPhonePopup }: IListCD): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            width: '50',
          },
          {
            Header: 'Số điện thoại',
            accessor: 'phoneNumber',
            width: '200',
            Cell: ({ row }: Cell<IListCD>) => {
              const handleClick = () => {
                onShowPhonePopup && onShowPhonePopup(row.original);
              };

              const handleKeyDown = (event: React.KeyboardEvent) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  handleClick();
                }
              };

              return (
                <p>
                  {row.original?.status === 1 ? (
                    <span
                      style={{ color: 'green', cursor: 'pointer' }}
                      onClick={handleClick}
                      onKeyDown={handleKeyDown}
                      role="button"
                      tabIndex={0}
                    >
                      {row.original?.phoneNumber}
                    </span>
                  ) : (
                    <span>{row.original?.phoneNumber}</span>
                  )}
                </p>
              );
            },
          },
          {
            Header: 'Thông tin chi tiết',
            width: '300',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <div className="p-2 h-100 d-flex flex-column">
                  <p>
                    Loại:{' '}
                    {row.original?.type != null
                      ? row.original?.type == 1
                        ? 'Saymee'
                        : 'Mobifone'
                      : ''}
                  </p>
                  <p>typeAge: {row.original?.typeAge}</p>
                  <p>location: {row.original?.location}</p>
                  <p>sex: {row.original?.sex}</p>
                  <p>arpuGroup: {row.original?.arpuGroup}</p>
                  <p>jobGroup: {row.original?.jobGroup}</p>
                  <p>vlr: {row.original?.vlr}</p>
                  <p>rfm: {row.original?.rfm}</p>
                </div>
              );
            },
          },
          {
            Header: 'Mã dự thưởng',
            accessor: 'code',
            width: '200',
          },
          {
            Header: 'Response ID',
            accessor: 'responseId',
            width: '200',
          },
          {
            Header: 'Trạng thái',
            accessor: 'status',
            width: '300',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <div className="p-2 h-100 d-flex flex-column">
                  <span
                    className={
                      row.original?.status == UserCampaignStatus.PASS
                        ? 'alert-success'
                        : row.original?.status == UserCampaignStatus.FAIL
                        ? 'alert-danger'
                        : 'alert-waring'
                    }
                  >
                    {row.original?.status == UserCampaignStatus.PASS
                      ? 'Trúng giải'
                      : row.original?.status == UserCampaignStatus.FAIL
                      ? 'Trượt'
                      : 'Chưa quay thưởng'}
                  </span>
                </div>
              );
            },
          },
          {
            Header: 'Tên phần thưởng',
            accessor: 'prizeName',
            width: '300',
          },
          {
            Header: 'Địa chỉ IP',
            accessor: 'ip',
            width: '200',
          },
          {
            Header: 'Xác suất quay số',
            accessor: 'probabilityLog',
            width: '400',
            Cell: ({ row }: Cell<IListCD>) => {
              if (row.original.probabilityLog) {
                const probabilityLog = JSON.parse(row.original.probabilityLog);
                return (
                  <div className="p-2 h-100 d-flex flex-column justify-content-center">
                    {probabilityLog.map((p: any, cIndex: number) => {
                      return (
                        <p key={cIndex}>
                          Phần thưởng: <b>{p.prizeName}</b> được quay với xác suất:{' '}
                          <b>{p.probability} %</b>
                        </p>
                      );
                    })}
                  </div>
                );
              } else {
                return '';
              }
            },
          },
          {
            Header: 'Ngày quay số',
            accessor: 'empty',
            width: '300',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <div className="p-2 h-100 d-flex flex-column justify-content-center">
                  <p>
                    {handleParseDate({
                      date: row.original?.spinDate,
                      format: DATE_FORMAT_2,
                    })}
                  </p>
                </div>
              );
            },
          },
          {
            Header: 'Ngày trao thưởng',
            accessor: 'awardDate',
            width: '300',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <div className="p-2 h-100 d-flex flex-column justify-content-center">
                  <p>
                    {handleParseDate({
                      date: row.original?.awardDate,
                      format: DATE_FORMAT_2,
                    })}
                  </p>
                </div>
              );
            },
          },
        ],
      },
    ],
    [onShowPhonePopup],
  );
  // const onShow = (data: any) => {
  //   onShowPopupAwardDate && onShowPopupAwardDate(data);
  // };

  return (
    <>
      <TableCommon
        classTable="table-serial-number"
        heightBody="calc(100vh - 290px)"
        data={dataSource}
        columns={columns}
        messageNoData="Không có kết quả tìm kiếm."
      />
    </>
  );
}
