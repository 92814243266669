import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface PopupProps {
  message: string[];
  show: boolean;
  handleVisible: () => void;
}

export default function ErrorPopup({ show, message, handleVisible }: PopupProps): JSX.Element {
  const [isSaymee, setIsSaymee] = useState<boolean>(false);

  useEffect(() => {
    if (location.pathname.includes('saymee')) {
      setIsSaymee(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <>
      <Modal
        show={show}
        size="sm"
        onHide={() => handleVisible()}
        dialogClassName="modal-landing modal-error"
        aria-labelledby="example-custom-modal-styling-title"
        contentClassName={isSaymee ? 'bg-modal-sm' : ''}
        centered
      >
        <Modal.Body>
          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-center">
              {message.length ? (
                message.map((m: string, index: number) => {
                  return <p key={index}>{m}</p>;
                })
              ) : (
                <div className="d-flex flex-column text-center">
                  <p>Hệ thống bận vui lòng quay lại sau.</p>
                  <p>Xin cám ơn!</p>
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button variant="danger fs-14 rounded-pill text-bold" onClick={() => handleVisible()}>
                Đóng lại
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
