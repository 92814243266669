import React from 'react';
import { Table } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { BlackList } from 'Types/Page/Campaign/RegisterCampaign';
import TableRowBlackList from './TableRowPopupBlackList';

interface TableProps {
  handleEdit: (item: any) => void;
  handleRemove: (item: any) => void;
  blackList: BlackList[];
  listFieldTable: string[];
  isDisabledItem?: boolean;
}

const TablePopupBlackList = ({
  handleRemove,
  handleEdit,
  blackList,
  listFieldTable,
}: TableProps): JSX.Element => {
  return (
    <div className="table-award">
      <Table className="table-campaign table-award-campaign header-light-gray" responsive>
        <thead>
          <tr>
            <th className="w-10">Stt</th>
            <th className="w-10">Ip</th>
            <th className="w-10">Số điện thoại</th>
            <th className="w-10">Địa chỉ</th>
            <th className="w-10">Tuổi</th>
            <th className="">Thêm</th>
            {/* {listFieldTable.map((value, index) => (
              <th key={index} className="w-10">
                {value}
              </th>
            ))} */}
            <th></th>
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {isEmpty(blackList) ? (
            <tr>
              <td className="text-center align-middle" colSpan={listFieldTable.length + 1}>
                Chưa đăng ký
              </td>
            </tr>
          ) : (
            blackList.map((value, index) => (
              <TableRowBlackList
                key={`item-${index}`}
                ip={value?.ip}
                index={value?.index ?? 0}
                phone={value?.phone}
                location={value?.location}
                typeAge={value?.typeAge}
                vlr={value?.vlr}
                vlrOperator={value?.vlrOperator}
                kndl={value?.kndl}
                kndlOperator={value?.kndlOperator}
                khln={value?.khln}
                khlnOperator={value?.khlnOperator}
                addFields={value?.addFields}
                handleEditItem={() => handleEdit({ ...value })}
                handleRemoveItem={() => handleRemove({ ...value })}
              />
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default React.memo(TablePopupBlackList);
