import PrizesTable from 'Components/Page/LandingPage/PrizesTable';
import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';

interface PopupProps {
  title: string;
  show: boolean;
  isSaymee: boolean;
  handleVisible: () => void;
}

export default function RulePopup({
  show,
  isSaymee,
  title,
  handleVisible,
}: PopupProps): JSX.Element {
  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={() => handleVisible()}
        dialogClassName="modal-90w modal-landing"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter h4"
            className="text-truncate d-flex justify-content-center m-auto"
          >
            <p className="title-header mb-0 text-truncate text-header">{title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3 rule-note">
            <p className="text-strong py-3">1. THỜI GIAN CHƯƠNG TRÌNH</p>
            <p>- Thời gian tham gia: Từ ngày 26/3/2025 đến hết ngày 25/12/2025</p>
            <p className="text-strong py-3">2. ĐỐI TƯỢNG THAM GIA</p>
            {isSaymee ? (
              <>
                <p className="ps-3">Đối với thuê bao Saymee:</p>
                <p className="ps-5">
                  + Tất cả các thuê bao trả trước Saymee đang hoạt động 01 chiều/ 02 chiều và có cập
                  nhật VLR tối thiểu 10 ngày kể từ tháng n-3 đến hết tháng n-1;
                </p>
                {/* <p className="ps-5">
              &emsp; &#10140; Trong các đối tượng này, có nhận diện thuê bao sử dụng dịch vụ chuyển
              vùng quốc tế từ 01/1/2024 đến nay để đưa ra bảng câu hỏi phù hợp.
            </p> */}
                <p className="ps-5">
                  + <b>Không</b> áp dụng cho thuê bao phục vụ test nghiệp vụ.
                </p>
              </>
            ) : (
              <>
                <p className="ps-3">
                  Đối với thuê bao MobiFone: Là thuê bao đang hoạt động 01 chiều/02 chiều thỏa mãn 1
                  trong 3 điều kiện:
                </p>
                <p className="ps-5">+ Hội viên Kết Nối Dài Lâu từ hạng Kim cương đến hạng Đồng;</p>
                <p className="ps-5">+ Khách Hàng Lâu Năm;</p>
                <p className="ps-5">+ Khách hàng thuộc mức cước từ 75.000đ trở lên.</p>
                <p className="ps-5">
                  <i>
                    {' '}
                    Lưu ý: <b>Không</b> áp dụng cho thuê bao liên lạc nghiệp vụ, thuê bao
                    FastConnect.
                  </i>
                </p>
              </>
            )}

            <p className="text-strong py-3">3. CÁCH THỨC THAM GIA</p>
            <p className="ps-3">
              - Sau khi hoàn tất trả lời các câu hỏi tại{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn/'} hệ
              thống cho phép khách hàng tham gia quay thưởng tại chính đường dẫn này. Tương ứng với
              mỗi lượt khảo sát được ghi nhận thành công, thuê bao sẽ nhận được một mã dự thưởng để
              tham gia quay thưởng, việc quay thưởng được tiến hành trực tuyến (online) qua{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn/'}
            </p>
            <p className="ps-3">
              - Trang{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn/'} có
              quy định rõ về đối tượng và thể lệ tham gia chương trình khuyến mại.
            </p>
            <p className="text-strong py-3">4. CƠ CẤU GIẢI THƯỞNG</p>
            <p>
              Tổng giá trị giải thưởng: <b>368.950.000 đồng</b>{' '}
              <i>(Bằng chữ: Ba trăm sáu mươi tám triệu chín trăm năm mươi nghìn đồng chẵn).</i>
            </p>
            <hr />
            <PrizesTable />
            <i>
              <p>Ghi chú:</p>
              <p className="ps-3">- Giá trị giải thưởng đã bao gồm thuế GTGT.</p>
              <p className="ps-3">
                - Giải nhất và Giải nhì là hiện vật, có thể quy đổi thành tiền mặt và được xác định
                trúng giải bằng hình thức quay thưởng trực tuyến.
              </p>
              <p className="ps-3">- Giải khuyến khích:</p>
              {isSaymee ? (
                <>
                  <p className="ps-5">
                    + Đối với thuê bao Saymee: dành cho 5.000 thuê bao đầu tiên tham gia chương
                    trình, trả lời đầy đủ các câu hỏi và được trao qua hình thức cộng tiền vào tài
                    khoản khuyến mại (thuê bao trả trước cộng KM1T, thuê bao trả sau cộng KM99T vào
                    các ngày 12 hàng tháng).
                  </p>
                </>
              ) : (
                <>
                  <p className="ps-5">
                    + Đối với thuê bao MobiFone: dành cho 5.000 thuê bao đầu tiên tham gia chương
                    trình, trả lời đầy đủ các câu hỏi và được trao qua hình thức cộng tiền vào tài
                    khoản khuyến mại (thuê bao trả trước cộng KM1T, thuê bao trả sau cộng KM99T vào
                    các ngày 12 hàng tháng).
                  </p>
                </>
              )}

              <p className="ps-5">
                + KM1T, KM99T (liên lạc nội mạng MobiFone: thoại và SMS, không được sử dụng cho dịch
                vụ data): hạn sử dụng 15 ngày.
              </p>
            </i>
            <p className="py-3 text-strong">5. QUY ĐỊNH VỀ BẰNG CHỨNG XÁC ĐỊNH TRÚNG THƯỞNG</p>
            <p className="ps-3">
              - Trước khi bắt đầu quay thưởng, hệ thống sẽ yêu cầu khách hàng nhập số điện thoại và
              mã dự thưởng
            </p>
            <p className="ps-3">
              - Khách hàng có thể truy cập{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn/'} để
              tra cứu kết quả trúng thưởng.
            </p>
            <p className="ps-3">
              - Một thuê bao hoàn thành khảo sát có 01 mã quay thưởng để tham gia chương trình.
            </p>
            <p className="ps-3">
              - Tại thời điểm nhận thưởng, khách hàng trúng giải phải là chính chủ của một thuê bao:
            </p>

            {isSaymee ? (
              <>
                <p className="ps-3">Đối với thuê bao Saymee:</p>
                <p className="ps-5">
                  + Tất cả các thuê bao trả trước Saymee đang hoạt động 01 chiều/ 02 chiều và có cập
                  nhật VLR tối thiểu 10 ngày kể từ tháng n-3 đến hết tháng n-1;
                </p>
                <p className="ps-5">
                  + <b>Không</b> áp dụng cho thuê bao phục vụ test nghiệp vụ.
                </p>
              </>
            ) : (
              <>
                <p className="ps-3">
                  Đối với thuê bao MobiFone: Là thuê bao đang hoạt động 01 chiều/02 chiều thỏa mãn 1
                  trong 3 điều kiện:
                </p>
                <p className="ps-5">+ Hội viên Kết Nối Dài Lâu từ hạng Kim cương đến hạng Đồng;</p>
                <p className="ps-5">+ Khách Hàng Lâu Năm;</p>
                <p className="ps-5">+ Khách hàng thuộc mức cước từ 75.000đ trở lên.</p>
                <p className="ps-5">
                  <i>Lưu ý</i>: <b>Không</b> áp dụng cho thuê bao liên lạc nghiệp vụ, thuê bao
                  FastConnect
                </p>
              </>
            )}

            <p className="py-3 text-strong">
              6. THỜI GIAN, ĐỊA ĐIỂM VÀ CÁCH THỨC XÁC ĐỊNH TRÚNG THƯỞNG
            </p>
            <p className="ps-3">
              Trong khoảng thời gian diễn ra chương trình, khách hàng trực tiếp quay thưởng trên{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn/'} Cụ
              thể:
            </p>
            <p className="ps-5">+ Bước 1: Truy cập đường link được cấp qua SMS.</p>
            <p className="ps-5">+ Bước 2: Nhận mã dự thưởng.</p>
            <p className="ps-5">+ Bước 3: Ấn nút “Quay thưởng”.</p>
            <p className="ps-3">
              - Kết quả quay số của khách hàng được cập nhật trên{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn/'}
            </p>
            <p className="ps-3">
              - Giải thưởng tương ứng với mỗi lượt quay sẽ được lựa chọn ngẫu nhiên bởi hệ thống máy
              tính vận hành{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn/'}, với
              cơ cấu giải thưởng bao gồm các giải nhất và giải nhì còn lại của chương trình.
            </p>
            <p className="py-3 text-strong">7. THỜI GIAN, ĐỊA ĐIỂM VÀ THỦ TỤC NHẬN THƯỞNG</p>
            <p className="ps-3">
              - Đối với khách hàng trúng giải khuyến khích tặng tiền vào tài khoản KM1T/KM99T, giải
              thưởng sẽ được cộng vào ngày 12 hàng tháng.
            </p>
            <p className="ps-3">
              - Đối với khách hàng trúng giải hiện vật, MobiFone sẽ liên hệ với khách hàng để xác
              minh và thông báo trúng thưởng. Khách hàng trúng thưởng tùy theo địa chỉ lưu trú, sẽ
              được Công ty Dịch vụ MobiFone Khu vực (CTKV) hẹn trao thưởng qua điện thoại. Công ty
              sẽ không chịu trách nhiệm đối với việc khách hàng không nhận được thông báo do thay
              đổi số điện thoại mà chưa thông báo cho MobiFone.
            </p>
            <p className="ps-3">
              - Khi đến nhận thưởng, khách hàng trúng thưởng sẽ phải xuất trình các giấy tờ sau:
            </p>
            <hr />
            <Table className="table-landing-page-prizes" responsive>
              <thead>
                <tr>
                  <th>Trường hợp</th>
                  <th>Giấy tờ</th>
                  <th>Quy cách</th>
                </tr>
              </thead>
              <tbody className="table-drag-item-body">
                <tr>
                  <td rowSpan={2}>Trường hợp thuê bao đứng tên cá nhân</td>
                  <td>Chứng minh nhân dân/ Hộ chiếu/ Căn cước công dân còn hiệu lực</td>
                  <td>
                    - Bản photo công chứng: 03 bản <br />- Bản gốc: 01 bản.
                  </td>
                </tr>
                <tr>
                  <td>Sim điện thoại chứa số thuê bao trúng giải</td>
                  <td>- Sim đăng ký thông tin cá nhân chính chủ của người nhận giải: 01 chiếc</td>
                </tr>
                <tr>
                  <td rowSpan={4}>Trường hợp thuê bao đứng tên cơ quan</td>
                  <td>
                    Chứng minh nhân dân/ Hộ chiếu/ Căn cước công dân còn hiệu lực của người đến làm
                    thủ tục
                  </td>
                  <td>
                    - Bản photo công chứng: 03 bản
                    <br />- Bản gốc: 01 bản.
                  </td>
                </tr>
                <tr>
                  <td>
                    Giấy đăng ký kinh doanh/ hoạt động hoặc tương đương của tổ chức sở hữu thuê bao
                  </td>
                  <td>- Bản photo công chứng: 03 bản.</td>
                </tr>
                <tr>
                  <td>Sim điện thoại chứa số thuê bao trúng giải</td>
                  <td>- Sim đăng ký thông tin cá nhân chính chủ của người nhận giải: 01 chiếc</td>
                </tr>
                <tr>
                  <td>Giấy giới thiệu của cơ quan</td>
                  <td>- Bản gốc: 01 bản</td>
                </tr>
              </tbody>
            </Table>
            <p className="ps-3">
              - Khách hàng phải có trách nhiệm chứng minh quyền chính chủ thuê bao theo đúng quy
              định. Nếu khách hàng không chứng minh được quyền chính chủ thuê bao thì MobiFone có
              quyền hủy quyền thụ hưởng của khách hàng.
            </p>
            <p className="ps-3">
              - Trong trường hợp chủ thuê bao không thể nhận giải có thể làm giấy ủy quyền có xác
              nhận của cấp có thẩm quyền cho người khác nhận thay:
            </p>
            <p className="ps-5">
              + Giấy ủy quyền đi làm thủ tục nhận giải (Bản gốc có chữ ký của người ủy quyền, người
              được ủy quyền và xác nhận của chính quyền địa phương nơi cư trú). Số lượng: 01 bản.
            </p>
            <p className="ps-5">
              + Chứng minh nhân dân/ Hộ chiếu/ Căn cước công dân còn hiệu lực của người được ủy
              quyền: Bản photo công chứng: 03 bản; Bản gốc; 01 bản.
            </p>
            <p className="ps-5">
              + Các giấy tờ khác của người ủy quyền như trường hợp người đó trực tiếp làm thủ tục.
            </p>
            <p className="ps-3">
              - Sau thời hạn được MobiFone thông báo mà khách hàng trúng thưởng không đến nhận giải
              thưởng sẽ mất quyền nhận thưởng, giải thưởng sẽ được xử lý trong trường hợp không có
              người trúng thưởng.
            </p>
            <p className="py-3 text-strong">8. TRÁCH NHIỆM THÔNG BÁO</p>
            <p className="ps-3">
              - Tổng công ty Viễn thông MobiFone có trách nhiệm thông báo đầy đủ chi tiết nội dung
              của thể lệ chương trình khuyến mại, trị giá giải thưởng, cách thức xác định khách hàng
              trúng thưởng… trên website:{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn/'}
            </p>
            <p className="ps-3">
              - Tổng công ty Viễn thông MobiFone sẽ thông báo đầy đủ danh sách khách hàng trúng
              thưởng trên ít nhất 01 phương tiện thông tin đại chúng và trên website{' '}
              {isSaymee ? 'https://chiase.mobifone.vn/saymee' : 'https://chiase.mobifone.vn'}. Khách
              hàng trúng giải sẽ được MobiFone thông báo trực tiếp qua điện thoại, nhắn tin hoặc một
              hình thức liên hệ phù hợp khác trong thời hạn 07 ngày kể từ ngày quay số trúng thưởng.
            </p>
            <p className="py-3 text-strong">9. QUY ĐỊNH KHÁC</p>
            <p className="ps-3">
              - Các trường hợp tham gia chương trình không đúng theo các bước được hướng dẫn ở trên
              được xem là không hợp lệ và MobiFone có quyền từ chối kết quả của người tham gia đó mà
              không cần báo trước.
            </p>
            <p className="ps-3">
              - Nếu người trúng thưởng còn nợ MobiFone bất kỳ số tiền nào thì số tiền nợ đó phải
              được thanh toán trước thời điểm trao thưởng. Nếu số tiền nợ đó không được thanh toán,
              người trúng thưởng sẽ bị tước giải thưởng.
            </p>
            <p className="ps-3">
              - Khách hàng trúng thưởng phải chịu chi phí đi lại cho việc nhận thưởng của mình và
              thuế thu nhập không thường xuyên (nếu có) theo quy định của pháp luật Việt Nam hiện
              hành.
            </p>
            <p className="ps-3">
              - Nếu được khách hàng trúng thưởng đồng ý, Tổng công ty Viễn thông MobiFone sẽ sử dụng
              tên và hình ảnh của khách hàng trúng thưởng cho mục đích quảng cáo thương mại.
            </p>
            <p className="ps-3">
              - Nghiêm cấm những trường hợp lợi dụng lỗi dịch vụ, gian lận và sử dụng phần mềm để
              gây rối, điều chỉnh hoặc can thiệp vào kết quả của CTKM. Nếu phát hiện ra người tham
              gia gian lận hoặc có dấu hiệu gian lận, MobiFone có quyền từ chối kết quả của người
              tham gia đó mà không cần báo trước.
            </p>
            <p className="ps-3">
              Trong trường hợp xảy ra tranh chấp liên quan đến chương trình khuyến mại này, Tổng
              công ty Viễn thông MobiFone có trách nhiệm trực tiếp giải quyết, nếu không thỏa thuận
              được tranh chấp sẽ được xử lý theo quy định của pháp luật Việt Nam hiện hành.
            </p>
          </div>

          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-center">
              <Button variant="danger fs-18 rounded-pill text-bold" onClick={() => handleVisible()}>
                Đóng lại
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
