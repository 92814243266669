import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import MessageError from '../MessageError';
import SpinnerComponent from '../SpinnerComponent';
import { LandingPageApis } from 'Datasource/LandingPage';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';

interface PopupProps {
  title?: string;
  isSaymee: boolean;
  show: boolean;
  handleVisible: () => void;
  handleDisableSpin: () => void;
}

export default function SpinCode({
  show,
  isSaymee,
  title,
  handleVisible,
  handleDisableSpin,
}: PopupProps): JSX.Element {
  const [code, setCode] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const campaignId = process.env.REACT_APP_CAMPAIGN_ID as string;
  const history = useHistory();

  const handleOnchange = (e: any) => {
    const code = e.target.value;
    if (!code || code.length < 5) {
      setMessage('Vui lòng nhập mã dự thưởng hợp lệ!');
      setIsDisable(true);
    } else {
      setMessage('');
      setIsDisable(false);
    }
    setCode(e.target.value);
  };

  const handleVerify = async () => {
    if (isDisable) return;
    setLoading(true);

    try {
      await LandingPageApis.verify({ campaignId: +campaignId, code: code });
      setLoading(false);
      handleVisible();
      handleDisableSpin();
      history.push(`/play-game?code=${code}`);
    } catch (error) {
      const { response } = error as AxiosError;
      response?.data?.message?.map((mes: string) => {
        setMessage(mes);
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Modal
        show={show}
        size="xl"
        onHide={() => handleVisible()}
        dialogClassName="modal-100w modal-landing modal-landing-verify-phone"
        aria-labelledby="example-custom-modal-styling-title"
        contentClassName={isSaymee ? 'bg-modal-sm' : ''}
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-truncate d-flex justify-content-center m-auto"
          >
            <p className="title-header mb-0 text-truncate text-header">{title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3">
            <div className="pt-3">
              <span className="text-strong">Lưu ý: </span>Mã dự thưởng hợp lệ được cung cấp cho quý
              khách khi hoàn thành xong khảo sát và chưa tham gia quay thưởng.
            </div>
          </div>
          <div className="d-flex justify-content-center pt-3">
            <Form.Control
              className="phone-number w-70 rounded-pill"
              value={code}
              maxLength={20}
              onChange={handleOnchange}
              placeholder="Vui lòng nhập mã dự thưởng"
              type="text"
            />
          </div>
          <MessageError classWrapper="d-flex justify-content-center" message={message} />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around px-5">
          <Button
            className="btn-cancel text-bold"
            variant="secondary"
            type="button"
            onClick={() => handleVisible()}
          >
            ĐÓNG LẠI
          </Button>
          <Button
            className="btn-verify text-bold"
            variant="primary"
            onClick={handleVerify}
            disabled={isDisable}
          >
            BẮT ĐẦU
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
