/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Form, Modal as FormModal, Button, Row, Col } from 'react-bootstrap';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import Modal from 'Components/Common/Modal';
import { SignUpRewardInitialState } from 'Constant/Campaign';
import { updateBlacklists, importFileBlacklists } from 'App/Features/Campaign';
import { useAppDispatch } from 'App/Store';
import schema from 'Components/Page/Campaign/RegisterCampaign/Schemas/RegisterSchema';
import { last } from 'lodash';
import ModalComponent from 'Components/Common/Modal';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import { BlackList } from 'Types/Page/Campaign/RegisterCampaign';
import TablePopupBlackList from './TablePopupBlackList';
import PaginationComponent from 'Components/Common/Pagination/index';
import { PER_PAGE_10 } from 'Constant';

import FormOperatorGroup from 'Components/Common/Form/FormOperatorGroup';
import { OperatorOptions } from 'Constant/Manger';
import { CampaignApis } from 'Datasource/Campaign';
import useMessage from 'Hooks/useMessage';
import { useAppSelector } from 'App/Store';
import * as XLSX from 'xlsx';

interface SignUpRewardProps {
  isDisable?: boolean;
  currentBlackList: any;
  onCloseModal?: any;
  handleShow?: (data?: any) => void;
}

const SignUpBlackListPopup = ({
  isDisable,
  onCloseModal,
  handleShow,
  currentBlackList,
}: SignUpRewardProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { openMessageError } = useMessage();
  const { file } = useAppSelector((state) => state.campaign);
  // const [visible, setVisible] = useState<boolean>(false);
  const [showModalDeleteBlacklist, setShowModalDeleteBlacklist] = useState<boolean>(false);

  const [indexBlackList, setIndexBlackList] = useState<any>(undefined);
  const [blackList, setBlackList] = useState<BlackList[]>([]);
  const [checkDisableAdd, setCheckDisable] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentDataBlackList, setCurrentDataBlackList] = useState<BlackList[]>([]);
  const methods = useForm<any>({
    defaultValues: SignUpRewardInitialState,
    resolver: yupResolver(schema),
  });
  const listFieldTable = ['Ip', 'Số diện thoại', 'Địa chỉ', 'Tuổi', 'Thêm'];

  const onSubmit = async () => {
    console.log('onSubmit', onSubmit);
    const blacklist = blackList;
    dispatch(updateBlacklists(blacklist));
    handleShow && handleShow(blacklist);
  };
  const { control, register, handleSubmit, watch, setValue, reset, getValues } = methods;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addFields',
  });
  // backlist
  const addField = () => {
    const currentFields = getValues('addFields');
    const lastIndex = currentFields.length > 0 ? currentFields[currentFields.length - 1].index : 0;
    append({ index: lastIndex + 1 });
    console.log(getValues('addFields'));
  };
  const handleAddBlackList = () => {
    const formData = getValues();
    console.log(formData);
    const arrayField = getValues('addFields');
    const blackitem = {
      ip: formData.ip as string,
      phone: formData.phone as string,
      location: formData.location as string,
      typeAge: formData.typeAge as string,
      vlr: formData.vlr as string,
      vlrOperator: formData.vlrOperator as string,
      kndl: formData.kndl as string,
      kndlOperator: formData.kndlOperator as string,
      khln: formData.khln as string,
      khlnOperator: formData.khlnOperator as string,
      addFields: JSON.stringify(arrayField),
    } as BlackList;
    setValue('ip', '');
    setValue('phone', '');
    setValue('location', '');
    setValue('typeAge', '');
    setValue('vlr', '');
    setValue('vlrOperator', '');
    setValue('kndl', '');
    setValue('kndlOperator', '');
    setValue('khln', '');
    setValue('khlnOperator', '');
    arrayField.forEach((item: any, index: number) => {
      arrayField[index].data = '';
      arrayField[index].operator = null;
    });
    if (indexBlackList == undefined) {
      const index = last(blackList)?.index;
      blackitem.index = index !== undefined ? index + 1 : 0;
      setBlackList([...blackList, blackitem]);
      return;
    }

    blackitem.index = indexBlackList;
    const newBlackList = blackList.map((p: BlackList, index) => {
      if (blackitem.index === index) {
        return {
          ...p,
          ip: blackitem.ip,
          phone: blackitem.phone,
          location: blackitem.location,
          typeAge: blackitem.typeAge,
          addFields: blackitem.addFields,
        };
      }
      return p;
    });
    setBlackList(newBlackList);
    setIndexBlackList(undefined);
    console.log(blackList);
    paginate(currentPage, newBlackList);
  };

  const handleUpdateBlacklist = (item: BlackList) => {
    console.log('update row blacklist', item);
    // console.log(getValues('addFields'));
    const oldFields = getValues('addFields');
    if (oldFields.length > 0) {
      oldFields.forEach((index: number) => {
        remove(index);
      });
    }
    const vlrOperator = OperatorOptions.find((obj) => obj.value == item.vlrOperator);
    const kndlOperator = OperatorOptions.find((obj) => obj.value == item.kndlOperator);
    const khlnOperator = OperatorOptions.find((obj) => obj.value == item.khlnOperator);
    console.log(vlrOperator, kndlOperator, khlnOperator);
    const addFields = item.addFields ? JSON.parse(item.addFields) : [];
    addFields.map((field: any) => {
      append({
        index: field.index,
        label: field.label,
        field: field.field,
        data: field.data,
        operator: field.operator,
      });
    });
    setValue('ip', item.ip);
    setValue('phone', item.phone);
    setValue('location', item.location);
    setValue('typeAge', item.typeAge);
    // setValue('vlr', item.vlr);
    // setValue('vlrOperator', item.vlrOperator);
    // setValue('kndl', item.kndl);
    // setValue('kndlOperator', item.kndlOperator);
    // setValue('khln', item.khln);
    // setValue('khlnOperator', item.khlnOperator);
    if (item.id) {
      setValue('id', item.id);
    }
    setIndexBlackList(item.index);
  };

  const deleteBlacklist = () => {
    if (indexBlackList !== undefined) {
      const item = blackList.filter((p) => {
        return p.index != indexBlackList;
      });
      setBlackList(item);
      setIndexBlackList(undefined);
    }
    setShowModalDeleteBlacklist(false);
  };
  const showPopupDeleteBlacklist = (item: BlackList) => {
    setShowModalDeleteBlacklist(true);
    setIndexBlackList(item.index);
  };
  useEffect(() => {
    console.log('currentBlackList', currentBlackList);
    const { blacklist, blacklists } = currentBlackList;

    if (blacklists) {
      setBlackList(blacklists);
      paginate(currentPage, blacklists);
    }
    if (blacklist) {
      // const index = blacklists.findIndex((i: BlackList) => i.id === blacklist.id);
      // const itemBlackList: any = {
      //   id: blacklist?.id,
      //   ip: blacklist?.ip,
      //   phone: blacklist?.phone,
      //   typeAge: blacklist?.typeAge,
      //   location: blacklist?.location,
      //   vlr: blacklist?.vlr,
      //   vlrOperator: blacklist?.vlrOperator,
      //   kndl: blacklist?.kndl,
      //   kndlOperator: blacklist?.kndlOperator,
      //   khln: blacklist?.khln,
      //   khlnOperator: blacklist?.khlnOperator,
      // };
      // reset(itemBlackList);
      // setIndexBlackList(index);
    }
  }, [reset, currentBlackList]);

  const handleCancel = () => {
    handleShow && handleShow();
  };
  const handleImportBlackList = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = '.xlsx';

    fileInput.addEventListener('change', function (event: Event) {
      const target = event.target as HTMLInputElement;
      if (target?.files?.[0]) {
        const file = target.files[0];
        dispatch(importFileBlacklists(file));
        handleReadFile(file);
      }
    });

    fileInput.click();
  };
  const handleDowloadTemplate = async () => {
    try {
      console.log('handleDowloadTemplate');
      const { data } = await CampaignApis.dowloadTempalteBlackList();
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'template_black_list.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      openMessageError(error);
    }
  };
  const handleReadFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result) {
        const arrayBuffer = event.target.result as ArrayBuffer;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json<BlackList>(sheet);
        const dataConver = converDataToTable(sheetData);
        setBlackList(dataConver);
        paginate(currentPage, dataConver);
      }
    };
    reader.readAsArrayBuffer(file);
  };
  const converDataToTable = (data: BlackList[]) => {
    const operators = ['>=', '<=', '>', '<', '='];
    return data.map(({ ip, phone, location, typeAge, ...rest }, index) => {
      const addFields = Object.entries(rest).map(([key, value]) => {
        let operator = '=';
        let valueStr = value?.toString() || '';
        const foundOperator = operators.find((op) => valueStr.startsWith(op));
        if (foundOperator) {
          operator = foundOperator;
          valueStr = valueStr.slice(foundOperator.length);
        }
        return {
          label: key,
          field: key,
          data: valueStr,
          operator: operator,
        };
      });
      return {
        index,
        ip,
        phone,
        location,
        typeAge,
        addFields: JSON.stringify(addFields),
      };
    });
  };
  const onChangePage = (page: number) => {
    setCurrentPage(page);
    paginate(page, blackList);
  };
  const paginate = (pageNumber: number, blackList: BlackList[]) => {
    if (blackList.length === 0 || pageNumber < 1) {
      setCurrentDataBlackList([]);
      return;
    }
    const startIndex = (pageNumber - 1) * PER_PAGE_10;
    const endIndex = pageNumber * PER_PAGE_10;
    const currentData: BlackList[] = blackList.slice(startIndex, endIndex);
    setCurrentDataBlackList(currentData);
  };
  return (
    <>
      <Modal
        show={currentBlackList?.visible}
        onCloseModal={onCloseModal}
        dialogClassName="modal-award"
        footerClassName="d-none"
        title={'Cài đặt danh sách chặn'}
        showCancelButton={false}
        isReviewModal
        size="xl"
      >
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') e.preventDefault();
            }}
            className={isDisable ? 'pe-none user-select-none' : 'pe-auto user-select-auto'}
          >
            <div className="mt-2">
              <Row>
                <Col className="mx-5">
                  <FormGroupInput
                    label="Ip"
                    labelMd="2"
                    colMd="10"
                    value={watch('ip')}
                    register={register('ip')}
                    onChange={(value) => setValue('ip', value)}
                    classForm="form-with-label-nowrap"
                  />
                  <FormInputNumber
                    name="phone"
                    labelMd="2"
                    colMd="10"
                    label="Số điện thoại"
                    pureType={true}
                    decimalScale={0}
                    maxLength={10}
                    allowLeadingZeros={true}
                    allowNegative={false}
                    value={watch('phone')}
                    onChange={(value: any) => setValue('phone', value)}
                  />
                  <FormGroupInput
                    label="Địa chỉ"
                    labelMd="2"
                    colMd="10"
                    value={watch('location')}
                    register={register('location')}
                    onChange={(value) => setValue('location', value)}
                    classForm="form-with-label-nowrap"
                  />
                  <FormInputNumber
                    name="typeAge"
                    labelMd="2"
                    colMd="10"
                    label="Tuổi"
                    pureType={true}
                    decimalScale={0}
                    maxLength={10}
                    allowLeadingZeros={true}
                    allowNegative={false}
                    value={watch('typeAge')}
                    onChange={(value: any) => setValue('typeAge', value)}
                  />
                  {fields.map((field, index) => (
                    <>
                      <Form.Group as={Row} className={`mb-3 `}>
                        <Col md={2}>
                          <Form.Control
                            value={watch(`addFields[${index}].label`)}
                            onChange={(event) => {
                              setValue(`addFields[${index}].label`, event.target.value);
                            }}
                            placeholder="Nhập tên biến"
                          />
                        </Col>
                        <Col md={2}>
                          <Form.Control
                            value={watch(`addFields[${index}].field`)}
                            onChange={(event) => {
                              setValue(`addFields[${index}].field`, event.target.value);
                            }}
                            placeholder="Nhập tên biến"
                          />
                        </Col>
                        <Col md={4}>
                          <FormOperatorGroup
                            label={false}
                            labelMd="2"
                            colMd="12"
                            colSelect="4"
                            colInput="8"
                            options={OperatorOptions}
                            onChange={(selectedValue: any, inputValue: any) => {
                              setValue(`addFields[${index}].data`, inputValue);
                              setValue(`addFields[${index}].operator`, selectedValue?.value || '');
                              if (inputValue && selectedValue?.value) {
                                setCheckDisable(true);
                              }
                            }}
                            placeholder=""
                            rowClass="mb-3"
                            value={
                              watch(`addFields[${index}].operator`)
                                ? {
                                    value: watch(`addFields[${index}].operator`),
                                    label: watch(`addFields[${index}].operator`),
                                  }
                                : null
                            }
                            inputValue={watch(`addFields[${index}].data`)}
                          />
                        </Col>
                        <Col md={2}>
                          <Button
                            variant="danger"
                            className="md-2 mb-4 mr-10"
                            type="button"
                            onClick={() => remove(index)}
                          >
                            Remove
                          </Button>
                        </Col>
                      </Form.Group>
                    </>
                  ))}
                  <div>
                    <Button
                      variant="primary"
                      className="md-2 mb-4 mr-10"
                      type="button"
                      onClick={() => {
                        addField();
                      }}
                    >
                      Add Field
                    </Button>
                  </div>
                  {/* <FormOperatorGroup
                    label="Vlr"
                    labelMd="2"
                    colMd="4"
                    options={OperatorOptions}
                    onChange={(selectedValue: any, inputValue: any) => {
                      setValue('vlr', inputValue);
                      setValue('vlrOperator', selectedValue?.value || '');
                    }}
                    placeholder=""
                    rowClass="mb-3"
                    value={
                      watch('vlrOperator')
                        ? { value: watch('vlrOperator'), label: watch('vlrOperator') }
                        : null
                    }
                    inputValue={watch('vlr')}
                  />

                  <FormOperatorGroup
                    label="Kndl"
                    labelMd="2"
                    colMd="4"
                    options={OperatorOptions}
                    onChange={(selectedValue: any, inputValue: any) => {
                      setValue('kndl', inputValue);
                      setValue('kndlOperator', selectedValue?.value || '');
                    }}
                    placeholder=""
                    rowClass="mb-3"
                    value={
                      watch('kndlOperator')
                        ? { value: watch('kndlOperator'), label: watch('kndlOperator') }
                        : null
                    }
                    inputValue={watch('kndl')}
                  />

                  <FormOperatorGroup
                    label="Khln"
                    labelMd="2"
                    colMd="4"
                    options={OperatorOptions}
                    onChange={(selectedValue: any, inputValue: any) => {
                      setValue('khln', inputValue);
                      setValue('khlnOperator', selectedValue?.value || '');
                    }}
                    placeholder=""
                    rowClass="mb-3"
                    value={
                      watch('khlnOperator')
                        ? { value: watch('khlnOperator'), label: watch('khlnOperator') }
                        : null
                    }
                    inputValue={watch('khln')}
                  /> */}
                </Col>
              </Row>

              <div>
                <Button
                  variant="primary"
                  className="md-2 mb-2 mr-10"
                  type="button"
                  onClick={() => {
                    handleAddBlackList();
                  }}
                  disabled={
                    !(
                      watch('ip') ||
                      watch('phone') ||
                      watch('location') ||
                      watch('typeAge') ||
                      checkDisableAdd
                    )
                  }
                >
                  {indexBlackList != undefined ? 'Cập nhật' : 'Thêm'}
                </Button>

                <Button
                  variant="primary"
                  className="md-2 mb-2 mr-10"
                  type="button"
                  onClick={() => {
                    handleImportBlackList();
                  }}
                >
                  Import
                </Button>
                <Button
                  variant="primary"
                  className="md-2 mb-2 mr-10"
                  type="button"
                  onClick={() => {
                    handleDowloadTemplate();
                  }}
                >
                  Template
                </Button>
                {file ? <div className="md-2 mb-4 mr-10">File import: {file.name}</div> : null}
              </div>
              <Col md={12} className="d-flex justify-content-end p-0 mb-2">
                <PaginationComponent
                  onClick={onChangePage}
                  activePage={currentPage ?? 1}
                  total={blackList.length ?? 0}
                  perPage={PER_PAGE_10}
                />
              </Col>
              <TablePopupBlackList
                handleEdit={(item) => handleUpdateBlacklist(item)}
                handleRemove={(item) => showPopupDeleteBlacklist(item)}
                blackList={currentDataBlackList}
                listFieldTable={listFieldTable}
              />
            </div>
          </Form>
        </FormProvider>

        <FormModal.Footer>
          <Button variant="secondary" className="btn-action" onClick={() => handleCancel()}>
            Hủy bỏ
          </Button>
          <Button
            variant="primary"
            className="btn-action"
            type="submit"
            onClick={() => {
              onSubmit();
            }}
          >
            Lưu lại
          </Button>
        </FormModal.Footer>
      </Modal>

      <ModalComponent
        show={showModalDeleteBlacklist}
        onCloseModal={() => setShowModalDeleteBlacklist(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="danger"
        onSubmitModal={() => deleteBlacklist()}
        title="Xác nhận xóa!"
        confirmText="Xóa bỏ"
      >
        <div>
          <p className="mb-2">Bạn có chắc muốn xóa danh sách chặn?</p>
        </div>
      </ModalComponent>
    </>
  );
};
export default SignUpBlackListPopup;
