/* eslint-disable @typescript-eslint/no-explicit-any */
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { LandingPageApis } from 'Datasource/LandingPage';
import { handleParseDate } from 'Utils/ParseDate';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

export default function ResultTable(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [userWin, setUserWin] = useState<any[]>([]);
  // {
  //   phone: '8473307614',
  //   codeJoin: 'ksfdgsgfweufs',
  //   prizeName: '33.990.000 VND',
  //   dateWin: '2023-05-12 04:28:30.294',
  //   dateReceive: '2023-05-12 04:28:30.294',
  // },

  const campaignId = process.env.REACT_APP_CAMPAIGN_ID as string;
  const handleGetUser = async () => {
    try {
      setLoading(true);
      const { data } = await LandingPageApis.getUserWin({ id: campaignId });
      if (data.length) {
        setUserWin(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Table className="table-landing-page-result" responsive>
        <thead>
          <tr>
            <th>STT</th>
            <th>SỐ ĐIỆN THOẠI</th>
            <th>MÃ DỰ THƯỞNG</th>
            <th>GIẢI THƯỞNG</th>
            <th>NGÀY QUAY THƯỞNG</th>
            <th>NGÀY TRAO THƯỞNG</th>
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {isEmpty(userWin) ? (
            <tr>
              <td className="text-center align-middle" colSpan={6}>
                Chưa có người trúng thưởng!
              </td>
            </tr>
          ) : (
            userWin.map((value, key) => {
              return (
                <tr key={`item-${key}`}>
                  <td>{key + 1}</td>
                  <td>{value.phoneNumber}</td>
                  <td>{value.codeJoin}</td>
                  <td>{value.prizeName}</td>
                  <td>
                    {handleParseDate({
                      date: value.dateWin,
                      format: DATE_FORMAT_2,
                    }) ?? '-'}
                  </td>
                  <td>
                    {' '}
                    {handleParseDate({
                      date: value.awardDate,
                      format: DATE_FORMAT_2,
                    }) ?? '-'}
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
    </>
  );
}
