import React from 'react';

import SpinningWheel from './new-lucky-wheel';

export default function LandingPage(): JSX.Element {
  return (
    <div>
      <SpinningWheel />
    </div>
  );
}
