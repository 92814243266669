import React, { useEffect, useState } from 'react';
import { Navbar, Image, Button, Form } from 'react-bootstrap';
import LogoMB from 'Static/Images/logo-mb.png';
import LogoSM from 'Static/Images/logo_sayme_header.png';
// import bg1 from 'Static/Images/bg_mobile_mbf.webp';
// import bgSm1 from 'Static/Images/bg-saymee-spin-mb.webp';
import RulePopup from 'Components/Common/LandingPagePopup/RulePopup';
import ShowTablePopup from 'Components/Common/LandingPagePopup/ShowTablePopup';
import PrizesTable from 'Components/Page/LandingPage/PrizesTable';
import ResultTable from 'Components/Page/LandingPage/ResultTable';
import VerifyPhonePopup from 'Components/Common/LandingPagePopup/VerifyPhonePopup';
import LuckyWheel from './lucky-wheel';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { LandingPageApis } from 'Datasource/LandingPage';
import { StringParam, useQueryParams } from 'use-query-params';
import FooterSM from 'Components/Page/LandingPage/FooteSM';
import FooterMB from 'Components/Page/LandingPage/FooterMB';
import GuidePopup from 'Components/Common/LandingPagePopup/GuidePopup';
import FQAPopup from 'Components/Common/LandingPagePopup/FQAPopup';
import SpinCode from 'Components/Common/LandingPagePopup/SpinCode';
import ErrorPopup from 'Components/Common/LandingPagePopup/ErrorPopup';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

export default function LandingPage(): JSX.Element {
  const [isPlayGame, setIsPlayGame] = useState<boolean>(false);
  const [isSaymee, setIsSaymee] = useState<boolean>(false);
  const [isDisableSpin, setIsDisableSpin] = useState<boolean>(true);
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [showGuideModal, setShowGuideModal] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [showFaqModal, setShowFaqModal] = useState(false);
  const [showPrizesModal, setShowPrizesModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [showSpinCodeModal, setShowSpinCodeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showVerifyPhoneModal, setShowVerifyPhoneModal] = useState(false);
  const [messageError, setMessageError] = useState<string[]>([]);
  const [isShowError, setIsShowError] = useState(false);
  const [isLimitUser, setIsLimitUser] = useState(false);
  const { personalId }: { personalId: string } = useParams();
  const [query] = useQueryParams({
    code: StringParam,
    isSaymee: StringParam,
  });

  const campaignId = process.env.REACT_APP_CAMPAIGN_ID as string;

  const params = {
    code: query.code as string,
    isSaymee: query.isSaymee as string,
    campaignId: +campaignId,
  };

  const showPopupVerifyPhone = () => {
    if (!isAccept) {
      setMessageError(['Quý khách vui lòng đồng ý điều kiện của chương trình!']);
      setIsShowError(true);

      return;
    }

    setShowVerifyPhoneModal(true);
  };

  const handleVerifySpin = async () => {
    setLoading(true);
    console.log(query);
    if (!query.code) {
      setMessageError(['Đã có lỗi xảy ra, không thể quay thưởng!']);
      setIsShowError(true);
      setLoading(false);

      return;
    }

    try {
      await LandingPageApis.verify(params);
      setLoading(false);
      setIsDisableSpin(false);
    } catch (error) {
      const message = [] as string[];
      const { response } = error as AxiosError;
      response?.data?.message?.map((mes: string) => {
        message.push(mes);
      });
      setMessageError(message);
      setIsShowError(true);
    } finally {
      setLoading(false);
    }
  };

  const getConfigCampaign = async () => {
    setLoading(true);
    try {
      const { data } = await LandingPageApis.getConfigCampaign({ id: campaignId });
      setIsLimitUser(!data.limitUser);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConfigCampaign();
    if (location.pathname == '/play-game') {
      setIsPlayGame(true);
      if (isDisableSpin) handleVerifySpin();
    }

    if (location.pathname.includes('saymee')) {
      setIsSaymee(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderProgramInfo = () => (
    <>
      <div className="box-info">
        {isSaymee ? (
          <>
            <p className="mb-3">
              Chương trình dành cho thuê bao Saymee <b>nhận được tin nhắn</b> mời tham gia chương
              trình (thuê bao trả trước đang hoạt động 01 chiều/ 02 chiều và có mở máy tối thiểu 10
              ngày trong vòng 03 tháng gần nhất)
            </p>
            <p className="pb-1">Lưu ý: Không áp dụng cho thuê bao phục vụ test nghiệp vụ</p>
          </>
        ) : (
          <>
            <p className="mb-3">
              Chương trình dành cho thuê bao MobiFone <b>nhận được tin nhắn</b> mời tham gia chương
              trình (Hội viên Kết Nối Dài Lâu, Khách Hàng Lâu Năm, Khách hàng đạt mức cước từ
              75.000đ trở lên)
            </p>
            <p className="pb-1">
              Lưu ý: Không áp dụng cho thuê bao liên lạc nghiệp vụ, thuê bao FastConnect
            </p>
          </>
        )}
        <p>
          Chi tiết thể lệ chương trình bấm{' '}
          <span
            className="text-decoration-underline"
            role="button"
            tabIndex={0}
            onClick={() => setShowRulesModal(true)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                setShowRulesModal(true);
              }
            }}
          >
            vào đây
          </span>
        </p>
        <p className="mb-3">
          <span>Chúng tôi cam kết bảo mật thông tin của Quý khách theo </span>
          <a
            href="https://tttb.mobifone.vn/nd-13/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-decoration-underline"
          >
            chính sách bảo vệ dữ liệu cá nhân của MobiFone
          </a>
          <span> và nội dung trả lời chỉ được sử dụng cho mục đích nghiên cứu</span>
        </p>
        {((personalId && personalId !== 'saymee') || isLimitUser) && (
          <>
            <Form.Check
              type="checkbox"
              id="isAccept"
              label="Tôi đã hiểu thể lệ và sẵn sàng tham gia"
              checked={isAccept}
              className="mb-0 checkbox-accept"
              onChange={(e) => setIsAccept(e.target.checked)}
            />
            <div className="d-flex justify-content-center mt-4">
              <Button className="button-verify-phone" onClick={showPopupVerifyPhone}>
                BẮT ĐẦU
              </Button>
            </div>
          </>
        )}
      </div>
      <div className="mt-1 pb-3 pt-2 text-center code-spin">
        <p>
          <span>*</span> Nếu đã có mã dự thưởng vui lòng ấn{' '}
          <span
            className="text-decoration-underline"
            onClick={() => setShowSpinCodeModal(true)}
            onKeyDown={() => setShowSpinCodeModal(true)}
          >
            vào đây.
          </span>{' '}
          <span>*</span>
        </p>
      </div>
    </>
  );

  return (
    <div className={`${isPlayGame ? 'wrapper-play ' : 'wrapper-verify '}wrapper-landing`}>
      <SpinnerComponent isLoading={loading} />
      <div className="position-relative">
        <div className="d-flex flex-column text-white bg-white">
          <Navbar className={`${isSaymee ? 'bg-header-saymee' : ''} header-landing`}>
            <div className="logo-game align-items-center w-100">
              <div className="px-0 sidebar">
                <span className="fs-14 d-sm-inline">
                  {isSaymee ? (
                    <Image src={LogoSM} className="img-logo-sm" />
                  ) : (
                    <Image src={LogoMB} className="img-logo-mb" />
                  )}
                </span>
              </div>
              <div className="btn-group-landing">
                <Button className="btn-landing" onClick={() => setShowGuideModal(true)}>
                  Hướng dẫn
                </Button>
                <Button className="btn-landing" onClick={() => setShowRulesModal(true)}>
                  Thể lệ
                </Button>
                <Button className="btn-landing" onClick={() => setShowPrizesModal(true)}>
                  Giải thưởng
                </Button>
                <Button className="btn-landing" onClick={() => setShowResultModal(true)}>
                  Kết quả
                </Button>
                <Button className="btn-landing" onClick={() => setShowFaqModal(true)}>
                  FAQ
                </Button>
              </div>
            </div>
          </Navbar>
        </div>
        {/* <div className={`child-1 d-none-desktop ${isPlayGame ? 'd-none' : ''}`}>
          {isSaymee ? <Image src={bgSm1} className="" /> : <Image src={bg1} className="" />}
          {renderProgramInfo()}
        </div> */}
        <div
          className={`child-2 d-flex justify-content-center ${
            isPlayGame
              ? isSaymee
                ? 'play-game-sm'
                : 'play-game-mbf'
              : isSaymee
              ? 'bg-child-sm-2'
              : 'bg-child-mbf-2'
          }`}
        >
          <div
            className={`main ${isSaymee ? 'bg-saymee-mb' : ''} ${
              isPlayGame ? 'bg-full-mobile' : 'bg-bottom-mobile'
            }`}
          >
            {/* <Image src={bgSm1} className="" /> */}
            <div className={`left ${isPlayGame ? 'd-none-mobile' : ''}`}>
              <div className="text-center"></div>
            </div>
            <div className={`right ${isPlayGame ? 'd-flex align-center' : ''}`}>
              {!isPlayGame ? (
                renderProgramInfo()
              ) : (
                <LuckyWheel
                  isDisable={isDisableSpin}
                  paramsSpin={params}
                  handleDisable={() => setIsDisableSpin(true)}
                />
              )}
            </div>
          </div>
        </div>
        {isSaymee ? <FooterSM /> : <FooterMB />}
      </div>
      <SpinCode
        show={showSpinCodeModal}
        isSaymee={isSaymee}
        title="Nhập mã dự thưởng"
        handleVisible={() => setShowSpinCodeModal(false)}
        handleDisableSpin={() => setIsDisableSpin(false)}
      ></SpinCode>
      <GuidePopup
        show={showGuideModal}
        title="Hướng dẫn"
        isSaymee={isSaymee}
        handleVisible={() => setShowGuideModal(false)}
      ></GuidePopup>
      <RulePopup
        show={showRulesModal}
        title="Thể lệ chương trình"
        isSaymee={isSaymee}
        handleVisible={() => setShowRulesModal(false)}
      ></RulePopup>
      <FQAPopup
        show={showFaqModal}
        title="FAQ"
        handleVisible={() => setShowFaqModal(false)}
      ></FQAPopup>
      <ShowTablePopup
        show={showResultModal}
        title="Kết quả"
        handleVisible={() => setShowResultModal(false)}
      >
        <ResultTable />
      </ShowTablePopup>
      <ShowTablePopup
        show={showPrizesModal}
        title="Giải thưởng"
        handleVisible={() => setShowPrizesModal(false)}
      >
        <PrizesTable />
      </ShowTablePopup>
      <VerifyPhonePopup
        show={showVerifyPhoneModal}
        isSaymee={isSaymee}
        personalID={personalId}
        handleVisible={() => setShowVerifyPhoneModal(false)}
      ></VerifyPhonePopup>
      <ErrorPopup
        show={isShowError}
        message={messageError}
        handleVisible={() => setIsShowError(false)}
      />
    </div>
  );
}
