/* eslint-disable react/no-unescaped-entities */
import ErrorPopup from 'Components/Common/LandingPagePopup/ErrorPopup';
import SpinResultPopup from 'Components/Common/LandingPagePopup/SpinResultPopup';
import { LandingPageApis } from 'Datasource/LandingPage';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

interface ParamsSpin {
  code: string;
  isSaymee: string;
  campaignId: number;
}

interface WheelProps {
  isDisable: boolean;
  paramsSpin: ParamsSpin;
  handleDisable: () => void;
}

const LuckyWheel = ({ isDisable, paramsSpin, handleDisable }: WheelProps): JSX.Element => {
  const [webkitTransform, setWebkitTransform] = useState<string>('');
  const [showSpinResultModal, setShowSpinResultModal] = useState(false);
  const [prizePick, setPrizePick] = useState<any>(undefined);
  const [messageError, setMessageError] = useState<string[]>([]);
  const [isShowError, setIsShowError] = useState(false);

  const prizeSetting = [
    {
      prizeName: 'Máy tính bảng iPad Gen 10 10.9 inch WiFi (64GB)',
      price: '9.900.000 VND',
      wheelPosition: [0, 7],
    },
    {
      prizeName: 'Đồng hồ thông minh Apple Watch Series 8 - GPS + Cellular, 41mm',
      price: '8.030.000 VND',
      wheelPosition: [2, 4, 6, 9],
    },
  ];

  let maxPos = 0;
  // const rollToCalculator = (indexOfItem: number) => {
  //   const prizes = 12;
  //   const _degree = indexOfItem * (360 / prizes) + 360 * 12 + maxPos;
  //   maxPos = _degree - indexOfItem * (360 / prizes);

  //   return `translate3d(0, 0, 0) rotateZ(${-1 * _degree}deg)`;
  // };
  const rollToCalculator = (indexOfItem: number) => {
    const prizes = 12;
    const offsetAngle = -270; // Dịch vòng quay bắt đầu từ góc 9h (trái)
    const _degree = indexOfItem * (360 / prizes) + 360 * 12 + maxPos + offsetAngle;
    maxPos = _degree - indexOfItem * (360 / prizes);

    return `translate3d(0, 0, 0) rotateZ(${-1 * _degree}deg)`;
  };

  const play = async () => {
    console.log('play');
    if (isDisable) return;
    handleDisable();
    let positionWheel = 1;
    try {
      const { data } = await LandingPageApis.spinRealtime(paramsSpin);
      if (data.positionWheel !== null) positionWheel = data.positionWheel;
    } catch (error) {
      const message = [] as string[];
      const { response } = error as AxiosError;
      response?.data?.message?.map((mes: string) => {
        message.push(mes);
      });
      setMessageError(message);
      setIsShowError(true);

      return;
    }

    prizeSetting.map((p) => {
      const position = p.wheelPosition.find((p) => p === positionWheel);
      if (position == 0 || position) {
        setPrizePick(p);
      }
    });
    const transform = rollToCalculator(positionWheel);
    setWebkitTransform(transform);

    await new Promise((resolve) => {
      setTimeout(resolve, 3500);
    });
    setShowSpinResultModal(true);
  };

  return (
    <>
      <div className="game-box">
        <span className="arrow"></span>
        <span className="bg-arrow"></span>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          {/* Canvas hiển thị vòng quay */}
          <canvas
            style={{ WebkitTransform: webkitTransform }}
            width="548"
            height="548"
            className="wheel-canvas"
          >
            <p>Sorry, your browser doesn't support canvas. Please try another.</p>
          </canvas>

          {/* <div
            onClick={() => play()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') play();
            }}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '100px', // Điều chỉnh kích thước vùng click
              height: '100px',
              transform: 'translate(-35%, -25%)',
              background: 'rgba(255, 0, 0, 0.0)', // Đặt `0.0` để vô hình
              cursor: 'pointer',
            }}
          /> */}
        </div>
        {/* <canvas
          onClick={() => play()}
          style={{ WebkitTransform: webkitTransform }}
          width="548"
          height="548"
          data-responsiveminwidth="180"
          data-responsivescaleheight="true"
        >
          <p>Sorry, your browser doesn't support canvas. Please try another.</p>
          
        </canvas> */}
        <div className="d-flex justify-content-center mt-4">
          <Button onClick={() => play()} className="btn-spin">
            QUAY THƯỞNG
          </Button>
        </div>
      </div>
      <SpinResultPopup
        show={showSpinResultModal}
        isSaymee={paramsSpin.isSaymee}
        prizePick={prizePick}
        handleVisible={() => setShowSpinResultModal(false)}
      />
      <ErrorPopup
        show={isShowError}
        message={messageError}
        handleVisible={() => setIsShowError(false)}
      />
    </>
  );
};

export default LuckyWheel;
