import { useState } from 'react';
import useMessage from 'Hooks/useMessage';
import { CampaignApis } from 'Datasource/Campaign';
import { useAppDispatch } from 'App/Store';
import { CampaignSteps, changeStep } from 'App/Features/Campaign';
import { useHistory } from 'react-router-dom';

const useCampaignDraft = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { openMessageError, openMessage } = useMessage();
  const dispatch = useAppDispatch();

  const handleChangeStep = (newStep: CampaignSteps) => {
    dispatch(changeStep({ step: newStep }));
  };

  const saveOrUpdateOfficial = (dataInput: any) => {
    const prizes = dataInput.prizes;
    const blacklists = dataInput.blacklists || [];
    const params = {
      prizes,
      ...(blacklists.length > 0 && { blacklists }), // Chỉ thêm blacklists nếu nó có giá trị
      ...dataInput?.overview,
    };

    if (dataInput.file) {
      const formdata = new FormData();
      formdata.append('data', JSON.stringify(params));
      formdata.append('file', dataInput.file);

      (async function fetchData() {
        setIsLoading(true);
        try {
          if (!dataInput?.id) {
            await CampaignApis.create({ params: formdata });
            openMessage({
              variant: 'success',
              message: 'Đăng ký thành công.',
            });
          } else {
            const id = dataInput.id;
            await CampaignApis.update({
              id,
              params: formdata,
            });
            openMessage({
              variant: 'success',
              message: 'Cập nhật thành công',
            });
          }
          setIsSuccess(true);
          history.push(`/admin/campaigns`);
        } catch (error) {
          openMessageError(error);
          setIsSuccess(false);
        } finally {
          setIsLoading(false);
        }
      })();
    } else {
      // Trường hợp không có file - gửi params trực tiếp
      (async function fetchData() {
        setIsLoading(true);
        try {
          if (!dataInput?.id) {
            await CampaignApis.create({ params });
            openMessage({
              variant: 'success',
              message: 'Đăng ký thành công.',
            });
          } else {
            const id = dataInput.id;
            await CampaignApis.update({
              id,
              params,
            });
            openMessage({
              variant: 'success',
              message: 'Cập nhật thành công',
            });
          }
          setIsSuccess(true);
          history.push(`/admin/campaigns`);
        } catch (error) {
          openMessageError(error);
          setIsSuccess(false);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  };

  const handleDeleteCampaign = async (id: string | number) => {
    try {
      await CampaignApis.deleteCampaign({ id });
      history.push('/admin/campaigns');
    } catch (error) {
      openMessageError(error);
    }
  };

  return {
    isSuccess,
    isLoading,
    saveOrUpdateOfficial,
    handleChangeStep,
    handleDeleteCampaign,
  };
};

export default useCampaignDraft;
