import React, { useState, useRef, useEffect } from 'react';
import './SpinningWheel.css';

interface Segment {
  name: string;
  color: string;
  image: string;
}

const SpinningWheel: React.FC = () => {
  const [rotation, setRotation] = useState<number>(0);
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const wheelRef = useRef<HTMLDivElement>(null);

  const segments: Segment[] = [
    { name: 'iPhone 14 Pro Max', color: '#4CAF50', image: '/api/placeholder/80/80' },
    { name: 'Apple iPad', color: '#FFC0CB', image: '/api/placeholder/80/80' },
    { name: 'Staycation', color: '#9C27B0', image: '/api/placeholder/80/80' },
    { name: '0.5% Extra ROI', color: '#2196F3', image: '/api/placeholder/80/80' },
    { name: 'Samsung LED', color: '#FFC107', image: '/api/placeholder/80/80' },
    { name: 'Range Rover', color: '#FF5722', image: '/api/placeholder/80/80' },
  ];

  const spinWheel = () => {
    if (!isSpinning) {
      const spinDuration = 5000;
      const spinRotation = 1440 + Math.random() * 360;

      setIsSpinning(true);
      setRotation((prevRotation) => prevRotation + spinRotation);

      setTimeout(() => {
        setIsSpinning(false);
      }, spinDuration);
    }
  };

  useEffect(() => {
    if (wheelRef.current) {
      wheelRef.current.style.transition = isSpinning
        ? 'transform 5s cubic-bezier(0.25, 0.1, 0.25, 1)'
        : 'none';
    }
  }, [isSpinning]);

  const LightBulb: React.FC<{ degree: number }> = ({ degree }) => (
    <div
      className="light-bulb"
      style={{
        transform: `rotate(${degree}deg) translate(192px) rotate(-${degree}deg)`,
        animationDelay: `${Math.random()}s`,
      }}
    />
  );

  return (
    <div className="spinning-wheel-container">
      <div className="wheel-wrapper">
        <div className="wheel-background">
          <div
            ref={wheelRef}
            className="wheel"
            style={{
              transform: `rotate(${rotation}deg)`,
            }}
          >
            {segments.map((segment, index) => (
              <div
                key={segment.name}
                className="segment"
                style={{
                  transform: `rotate(${index * 60}deg)`,
                  background: `linear-gradient(135deg, ${segment.color} 0%, ${segment.color}cc 100%)`,
                }}
              >
                <div className="segment-content">
                  <img src={segment.image} alt={segment.name} className="segment-image" />
                  <span className="segment-name">{segment.name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="wheel-border">
          {[...Array(24)].map((_, i) => (
            <LightBulb key={i} degree={i * 15} />
          ))}
        </div>
        <div className="wheel-pointer"></div>
      </div>
      <button onClick={spinWheel} disabled={isSpinning} className="spin-button">
        {isSpinning ? 'SPINNING...' : 'SPIN'}
      </button>
    </div>
  );
};

export default SpinningWheel;
