/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
export const CampaignApis = {
  getAll: ({ limit, page }: { limit?: number; page?: number }) =>
    brAxios.get(`/campaigns?page=${page ?? 1}&limit=${limit ?? 20}`),
  getUsersCampaign: ({
    id,
    page,
    limit,
    keyword,
    status,
    startDate,
    endDate,
  }: {
    id: string;
    limit?: number;
    page?: number;
    keyword?: string;
    status?: string;
    startDate?: string;
    endDate?: string;
  }) =>
    brAxios.get(
      `/campaigns/${id}/users?page=${page ?? 1}&limit=${
        limit ?? 20
      }&keyword=${keyword}&status=${status}${startDate ? `&startDate=${startDate}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }`,
    ),
  getDetail: ({ id }: { id: string }) => brAxios.get(`/campaigns/${id}`),
  create: ({ params }: { params: any }) => brAxios.postFormData('/campaigns', params),
  update: ({ id, params }: { id: string; params: any }) => {
    return brAxios.putFormData(`campaigns/${id}`, params);
  },
  deleteCampaign: ({ id }: { id: string | number }) =>
    brAxios.delete(`/record-company/campaigns/${id}`),
  dowloadTempalteBlackList: () =>
    brAxios.get(`/campaigns/get-template-black-list`, {
      responseType: 'blob',
    }),
  updateAwardDate: ({ id, params }: { id: string; params: any }) => {
    return brAxios.put(`/user-campaign/${id}`, params);
  },
};
