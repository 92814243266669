import React, { useState, useEffect, PropsWithChildren } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Select, {
  components as componentContent,
  GroupTypeBase,
  ValueContainerProps,
} from 'react-select';
import { Search as IconSearch } from 'react-bootstrap-icons';
import { ISelect } from 'Types/Common';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { REGEX_VN } from 'Constant';

export default function FormOperatorGroup(props: any): JSX.Element {
  const {
    label,
    classLabel,
    options,
    onChange,
    defaultValue,
    classNameSlc,
    placeholder,
    errorMessage,
    labelMd,
    colMd,
    colLg,
    labelLg,
    rowClass,
    colClass,
    isMulti,
    register,
    disabled = false,
    isReview = false,
    isSearch,
    classBoxErr,
    onClickError,
    hintText,
    colSelect,
    colInput,
    value, // Prop mới để kiểm soát giá trị của component
    inputValue, // Prop mới để kiểm soát giá trị của input
  } = props;

  const [selectValue, setSelectValue] = useState<ISelect | null>(value || defaultValue);
  const [localInputValue, setLocalInputValue] = useState<string | number>(inputValue || '');

  useEffect(() => {
    setSelectValue(value || null);
  }, [value]);

  useEffect(() => {
    setLocalInputValue(inputValue || '');
  }, [inputValue]);

  const handleSelectChange = (selected: ISelect | null) => {
    setSelectValue(selected);
    onChange(selected, localInputValue);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const regex = REGEX_VN;
    if (regex.test(newValue)) {
      setLocalInputValue(newValue);
      onChange(selectValue, newValue);
    }
  };
  const ValueContainer = ({
    children,
    ...props
  }: PropsWithChildren<ValueContainerProps<ISelect, boolean, GroupTypeBase<ISelect>>>) => {
    return (
      componentContent.ValueContainer && (
        <componentContent.ValueContainer {...props}>
          {!!isSearch && (
            <div>
              <IconSearch />
            </div>
          )}
          <div className="ps-1">{children}</div>
        </componentContent.ValueContainer>
      )
    );
  };

  return (
    <Form.Group as={Row} className={rowClass}>
      {label && (
        <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
          {labelRequire(label)}
        </Form.Label>
      )}
      {/* Select Field */}
      <Col md={colSelect ? colSelect : 2} lg={colLg} className={colClass}>
        <Select
          {...register}
          isMulti={isMulti}
          menuPlacement="auto"
          options={options}
          className={`w-100 ${classNameSlc ?? ''}`}
          placeholder={placeholder}
          onChange={!isReview ? handleSelectChange : () => null}
          defaultValue={defaultValue}
          value={selectValue}
          components={{
            IndicatorSeparator: () => null,
            ValueContainer,
          }}
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            menuPortal: (base) => ({
              ...base,
              zIndex: 1200,
            }),
          }}
          menuPortalTarget={document.body}
          isDisabled={disabled}
          noOptionsMessage={() => '検索結果がありません。'}
        />
        <div onClick={onClickError} aria-hidden="true" className={classBoxErr}>
          <MessageError message={errorMessage} />
        </div>
        <div className="fs-12 mt-1 w-max-content">{hintText}</div>
      </Col>

      {/* Input Field */}
      <Col md={colInput ? colInput : Number(colMd) - 2} lg={colLg} className={colClass}>
        <Form.Control
          {...register}
          value={inputValue ?? ''}
          onChange={handleInputChange}
          type="text" // You can change this as needed
          placeholder={placeholder}
          disabled={disabled || isReview}
          autoComplete="off"
        />
        <MessageError message={errorMessage} />
      </Col>
    </Form.Group>
  );
}
