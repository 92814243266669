import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface PopupProps {
  children: React.ReactNode;
  title?: string;
  show: boolean;
  handleVisible: () => void;
}

export default function ShowTablePopup({
  show,
  children,
  title,
  handleVisible,
}: PopupProps): JSX.Element {
  console.log(children);
  return (
    <div className="landing-wrapper-modal">
      <Modal
        show={show}
        size="xl"
        onHide={() => handleVisible()}
        dialogClassName="modal-200w modal-landing"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-truncate d-flex justify-content-center m-auto"
          >
            <p className="title-header mb-0 text-truncate text-header">{title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3">{children}</div>

          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-center">
              <Button variant="danger fs-18 rounded-pill text-bold" onClick={() => handleVisible()}>
                Đóng lại
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
