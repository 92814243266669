import React from 'react';
import { Table } from 'react-bootstrap';
import TableRow from './TableRow';

export default function PrizesTable(): JSX.Element {
  const prizes = [
    {
      place: 'Giải nhất',
      name: 'Máy tính bảng iPad Gen 10 10.9 inch WiFi (64GB)',
      value: '9.900.000 VNĐ',
      amount: '15',
      totalValue: '148.500.000',
    },
    {
      place: 'Giải nhì',
      name: 'Đồng hồ thông minh Apple Watch Series 8 - GPS + Cellular, 41mm',
      value: '8.030.000 VND',
      amount: '15',
      totalValue: '120.450.000',
    },
    {
      place: 'Giải khuyến khích',
      name: 'Cộng tiền vào tài khoản khuyến mại (KM1T/KM99T)',
      value: '10.000 VND',
      amount: '10.000',
      totalValue: '100.000.000',
    },
    {
      place: 'Tổng giá trị hàng hóa, dịch vụ dùng để khuyến mại (đã bao gồm thuế GTGT)',
      totalValue: '368.950.000',
    },
  ];
  return (
    <>
      <Table className="table-landing-page-prizes" responsive>
        <thead>
          <tr style={{ borderBottom: '1px solid #dee2e6' }}>
            <th>Giải thưởng</th>
            <th>Chi tiết giải thưởng</th>
            <th>
              Trị giá <br /> (đã bao gồm VAT)
            </th>
            <th>Số lượng</th>
            <th>Tổng giá trị (VND)</th>
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {prizes.map((value, index) => {
            const isRowTotal = prizes.length == index + 1;
            return (
              <TableRow
                key={`item-${index}`}
                name={value.name}
                place={value.place}
                value={value.value}
                amount={value.amount}
                totalValue={value.totalValue}
                isRowTotal={isRowTotal}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
