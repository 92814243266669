import React from 'react';
import { Button } from 'react-bootstrap';

interface RowTypes {
  index: number;
  ip?: string;
  phone?: string;
  location: string;
  typeAge: string;
  vlr?: string;
  vlrOperator?: string;
  kndl?: string;
  kndlOperator?: string;
  khln?: string;
  khlnOperator?: string;
  className?: string;
  isShowError?: boolean;
  addFields?: string;
  handleEditItem?: () => void;
  handleRemoveItem?: () => void;
  isDisabled?: boolean;
}

const TableRowBlackList = ({
  index,
  ip,
  phone,
  location,
  typeAge,
  // vlr,
  // vlrOperator,
  // kndl,
  // kndlOperator,
  // khln,
  // khlnOperator,
  addFields,
  className,
  handleEditItem,
  handleRemoveItem,
}: RowTypes): JSX.Element => {
  let parsedAddFields: any[] = [];
  if (addFields) {
    try {
      parsedAddFields = addFields ? JSON.parse(addFields) : [];
    } catch (error) {
      console.error('Failed to parse addFields', error);
    }
  }

  return (
    <tr className={`tr-drag ${className}`}>
      <td>{index + 1}</td>
      <td>{ip}</td>
      <td>{phone}</td>
      <td>{location}</td>
      <td>{typeAge}</td>
      <td>
        {parsedAddFields.map((value, index) => {
          return (
            <div key={`item-${index}`}>
              <p>
                {value.label}({value.field}): {value.operator === '=' ? '' : value.operator}{' '}
                {value.data}
              </p>
            </div>
          );
        })}
      </td>
      {/* {parsedAddFields.length > 0 &&
        parsedAddFields.map((field, index) => (
          <td key={index}>
            {field.operator === '=' ? '' : field.operator} {field.data}
          </td>
        ))} */}
      <td>
        <div className="text-end">
          <Button onClick={handleEditItem} className="me-3">
            Chỉnh sửa
          </Button>
          <Button onClick={handleRemoveItem} className="btn-danger">
            Xóa
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default TableRowBlackList;
