import React, { PropsWithChildren, useState } from 'react';
import { Button } from 'react-bootstrap';

interface LoadMoreUserWin {
  userCampaigns?: any;
  prizes?: any;
}
export default function LoadMoreUserWin({
  userCampaigns,
  prizes,
}: PropsWithChildren<LoadMoreUserWin>): JSX.Element {
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const userCampaignsShow = isLoadMore ? userCampaigns : userCampaigns.slice(0, 3);
  return (
    <div>
      {userCampaignsShow.map((uc: any, cIndex: number) => {
        return (
          <p key={cIndex}>
            Số điện thoại:<b>0{uc?.user?.phoneNumber} </b>
            đã trúng:<b> {prizes.find((p: any) => p?.id == uc?.prizeId)?.name}</b>
          </p>
        );
      })}
      {userCampaigns.length > 3 && (
        <Button onClick={() => setIsLoadMore(!isLoadMore)} className="btn-focus-none fs-14 mt-3">
          {isLoadMore ? 'Ẩn bớt' : 'Xem thêm '}
        </Button>
      )}
    </div>
  );
}
