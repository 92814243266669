import { PrizeProbability } from 'Types/Page/Campaign/RegisterCampaign';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { prizePickType } from 'Constant/Campaign';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { handleParseDate } from 'Utils/ParseDate';
import { find, isEmpty } from 'lodash';

interface RowTypes {
  name?: string;
  awardType?: string;
  positionWheel?: number;
  settingProbabilities: PrizeProbability[];
  className?: string;
  isShowError?: boolean;
  handleEditItem?: () => void;
  isDisabled?: boolean;
}

const TableRow = ({
  name,
  awardType,
  positionWheel,
  settingProbabilities,
  className,
  handleEditItem,
}: RowTypes): JSX.Element => {
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const settingProbabilitiesShow = isLoadMore
    ? settingProbabilities
    : settingProbabilities.slice(0, 2);

  return (
    <tr className={`tr-drag ${className}`}>
      <td>{name}</td>
      <td>{awardType}</td>
      <td>{positionWheel}</td>
      <td>
        {isEmpty(settingProbabilities)
          ? 'Chưa cài đặt thời gian trúng giải'
          : settingProbabilitiesShow.map((value, index) => {
              const pickType = find(prizePickType, (option) => option.value === value.pickType);
              return (
                <div key={`item-${index}`}>
                  <p>Đối tượng: {pickType?.label}</p>
                  <p>
                    Số lượng: {value.quantity}, Còn:{' '}
                    {value.quantity - (value.countWin ? value.countWin : 0)}
                  </p>
                  <p>Xác suất: {value.probability}%</p>
                  <p>
                    Thời gian bắt đầu:{' '}
                    {handleParseDate({
                      date: value.startDate,
                      format: DATE_FORMAT_2,
                    })}
                  </p>
                  <p>
                    Thời gian kết thúc:{' '}
                    {handleParseDate({
                      date: value.endDate,
                      format: DATE_FORMAT_2,
                    })}
                  </p>
                  {index == settingProbabilitiesShow.length - 1 ? '' : <hr className="my-3" />}
                </div>
              );
            })}
        {settingProbabilities.length > 2 && (
          <Button onClick={() => setIsLoadMore(!isLoadMore)} className="btn-focus-none fs-14 mt-4">
            {isLoadMore ? 'Ẩn bớt' : 'Xem thêm '}
          </Button>
        )}
      </td>
      <td>
        <div className="text-end">
          <Button onClick={handleEditItem}>Chỉnh sửa</Button>
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
