interface SelectOption {
  label: string;
  value: string;
}

const RoleOptionsType: SelectOption[] = [
  {
    label: 'MASTER',
    value: 'MASTER',
  },
  {
    label: 'MANAGER',
    value: 'MANAGER',
  },
];
const OperatorOptions: SelectOption[] = [
  {
    label: '>',
    value: '>',
  },
  {
    label: '>=',
    value: '>=',
  },
  {
    label: '<',
    value: '<',
  },
  {
    label: '<=',
    value: '<=',
  },
  {
    label: '=',
    value: '=',
  },
];

const NOTE_UPDATE_PASSWORD =
  'Trường hợp muốn đổi mật khẩu của admin vui lòng điền mật khẩu mới tại đây!';

export { RoleOptionsType, NOTE_UPDATE_PASSWORD, OperatorOptions };

export type { SelectOption };
