/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { Form, Modal as FormModal, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import Modal from 'Components/Common/Modal';
// import { useAppDispatch } from 'App/Store';
import schema from 'Components/Page/Campaign/RegisterCampaign/Schemas/AwardDateSchema';
import CPFormGroupDate from 'Components/Common/Form/FormGroupDate/CPFormGroupDate';
import { normalizeDate, formatDate } from 'Utils/DateTime';
import { DATE_FORMAT_20, DATE_FORMAT_19 } from 'Constant/Date';

interface AwardDatePopup {
  isDisable?: boolean;
  currentAwardDate: any;
  onCloseModal?: any;
  handleShow?: () => void;
  handleSubmitForm: (data: any) => void;
}

const SignUpRewardPopup = ({
  isDisable,
  currentAwardDate,
  onCloseModal,
  handleShow,
  handleSubmitForm,
}: AwardDatePopup): JSX.Element => {
  const methods = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = () => {
    console.log(currentAwardDate);
    // return;
    const data = {
      awardDate: getValues('awardDate'),
      userCampaignId: currentAwardDate.data?.id,
    };
    handleSubmitForm(data);
    handleShow && handleShow();
  };
  const handleCancel = () => {
    handleShow && handleShow();
  };
  useEffect(() => {
    if (currentAwardDate.data) {
      reset({ awardDate: currentAwardDate.data.awardDate });
    }
  }, [currentAwardDate]);
  return (
    <>
      <Modal
        show={currentAwardDate?.visible}
        onCloseModal={onCloseModal}
        dialogClassName="modal-award"
        footerClassName="d-none"
        title={'Cập nhật ngày trao giải'}
        showCancelButton={false}
        isReviewModal
        size="lg"
      >
        <Form
          onSubmit={handleSubmit(onSubmit)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          className={isDisable ? 'pe-none user-select-none' : 'pe-auto user-select-auto'}
        >
          <FormProvider {...methods}>
            <div className="mt-2">
              <CPFormGroupDate
                label="Ngày trao giải:"
                labelMd="2"
                colMd="10"
                placeholder="Chọn ngày trao giải"
                selected={normalizeDate(watch('awardDate'))}
                dateFormat={DATE_FORMAT_20}
                showTime={true}
                onChange={(date: any) => setValue('awardDate', formatDate(date, DATE_FORMAT_19))}
                errorMessage={errors.awardDate?.message}
              />
            </div>
          </FormProvider>
        </Form>

        <FormModal.Footer>
          <Button variant="secondary" className="btn-action" onClick={() => handleCancel()}>
            Hủy bỏ
          </Button>
          <Button
            variant="primary"
            className="btn-action"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            Lưu lại
          </Button>
        </FormModal.Footer>
      </Modal>
    </>
  );
};
export default SignUpRewardPopup;
