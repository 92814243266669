/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { prizeOptionsType } from 'Constant/Campaign';
import { find, isEmpty } from 'lodash';
import { BlackList } from 'Types/Page/Campaign/RegisterCampaign';
import TablePrizeRow from './TablePrizeRow';
import TableBlackListRow from './TableBlackListRow';
import PaginationComponent from 'Components/Common/Pagination/index';
import { PER_PAGE_10 } from 'Constant';
interface TableProps {
  blacklists: BlackList[];
}

const PrizesTable = ({ blacklists }: TableProps): JSX.Element => {
  const [items, setItems] = useState(blacklists);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentDataBlackList, setCurrentDataBlackList] = useState<BlackList[]>([]);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
    paginate(page, items);
  };
  const paginate = (pageNumber: number, blackList: BlackList[]) => {
    if (blackList.length === 0 || pageNumber < 1) {
      setCurrentDataBlackList([]);
      return;
    }
    const startIndex = (pageNumber - 1) * PER_PAGE_10;
    const endIndex = pageNumber * PER_PAGE_10;
    const currentData: BlackList[] = blackList.slice(startIndex, endIndex);
    setCurrentDataBlackList(currentData);
  };
  useEffect(() => {
    setItems(blacklists);
    paginate(currentPage, blacklists);
  }, [blacklists]);
  return (
    <div className="table-award">
      <Table className="table-campaign table-award-campaign header-light-gray" responsive>
        <thead>
          <tr>
            <th className="w-10">Ip</th>
            <th className="w-10">Số điện thoại</th>
            <th className="w-10">Vị trí</th>
            <th className="w-10">Ngưỡng tuổi</th>
            <th className="">Cấu hình thêm</th>
            {/* <th className="w-10">Kndl</th>
            <th className="w-10">Khln</th> */}
          </tr>
        </thead>
        <tbody className="table-drag-item-body">
          {isEmpty(items) ? (
            <tr>
              <td className="text-center align-middle" colSpan={4}>
                Chưa đăng ký
              </td>
            </tr>
          ) : (
            currentDataBlackList.map((value, key) => {
              return (
                <TableBlackListRow
                  key={`item-${key}`}
                  ip={value?.ip}
                  phone={value?.phone}
                  location={value?.location}
                  typeAge={value?.typeAge}
                  vlr={value?.vlr}
                  vlrOperator={value?.vlrOperator}
                  kndl={value?.kndl}
                  kndlOperator={value?.kndlOperator}
                  khln={value?.khln}
                  khlnOperator={value?.khlnOperator}
                  addFields={value?.addFields}
                />
              );
            })
          )}
        </tbody>
      </Table>
      <PaginationComponent
        onClick={onChangePage}
        activePage={currentPage ?? 1}
        total={items.length ?? 0}
        perPage={PER_PAGE_10}
      />
    </div>
  );
};

export default React.memo(PrizesTable);
